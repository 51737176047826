(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/sportkryss-numbers/assets/javascripts/sportkryss-numbers.js') >= 0) return;  svs.modules.push('/components/tipsen/sportkryss-numbers/assets/javascripts/sportkryss-numbers.js');
"use strict";

const {
  ReactBadge: Badge
} = svs.ui;
const {
  setUpSportKryssData
} = svs.components.tipsen.SportkryssMybetsResult.utils;
const {
  useMemo
} = React;
const SportkryssNumbers = _ref => {
  let {
    sportkryssetResultData,
    sportkryssetBetData,
    title,
    branding
  } = _ref;
  const sportKryssData = useMemo(() => setUpSportKryssData({
    sportkryssetResultData,
    sportkryssetBetData
  }), [sportkryssetResultData, sportkryssetBetData]);
  return React.createElement("div", {
    className: "pg_sportkryss__row__container"
  }, title && React.createElement("div", {
    className: "pg_sportkryss__title"
  }, title), React.createElement("div", {
    className: "pg_sportkryss__row pg_sportkryss__row--".concat(sportKryssData !== null && sportKryssData !== void 0 && sportKryssData.showCorrects ? 'show-corrects' : 'hide-corrects')
  }, (sportKryssData === null || sportKryssData === void 0 ? void 0 : sportKryssData.row) && React.createElement("div", {
    className: "pg_sportkryss__numbers"
  }, sportKryssData.row.map((numberObj, index) => React.createElement(Badge, {
    branding: branding,
    className: "f-500 pg_sportkryss__number pg_sportkryss__number--".concat(numberObj.isCorrect ? 'correct' : 'incorrect')
    ,
    key: index
  }, numberObj.number))), (sportKryssData === null || sportKryssData === void 0 ? void 0 : sportKryssData.showCorrects) && React.createElement(React.Fragment, null, React.createElement("div", {
    className: "pg_sportkryss__nr_of_corrects pg_sportkryss__nr_of_corrects__front"
  }, React.createElement("p", {
    className: "pg_sportkryss__nr_of_corrects__numbers"
  }, "".concat(sportKryssData.correctNumbersFromLeft, " r\xE4tt")), React.createElement("p", {
    className: "pg_sportkryss__nr_of_corrects__text"
  }, "framifr\xE5n")), React.createElement("div", {
    className: "pg_sportkryss__nr_of_corrects pg_sportkryss__nr_of_corrects__back"
  }, React.createElement("p", {
    className: "pg_sportkryss__nr_of_corrects__numbers"
  }, "".concat(sportKryssData.correctNumbersFromRight, " r\xE4tt")), React.createElement("p", {
    className: "pg_sportkryss__nr_of_corrects__text"
  }, "bakifr\xE5n")))));
};
setGlobal('svs.components.tipsen.SportkryssMybetsResult.SportkryssNumbers', SportkryssNumbers);

 })(window);