(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/sportkryss-numbers/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/tipsen/sportkryss-numbers/assets/javascripts/utils.js');
"use strict";

const setUpResultData = sportkryssetResultData => {
  const row = sportkryssetResultData.map(sportKryssResultNumber => ({
    number: sportKryssResultNumber,
    isCorrect: true
  }));
  return {
    correctNumbersFromLeft: 0,
    correctNumbersFromRight: 0,
    row,
    showCorrects: false
  };
};
const setUpBetDataWithResult = _ref => {
  let {
    sportkryssetResultData,
    sportkryssetBetData
  } = _ref;
  let correctNumbersFromLeft = 0;
  let correctNumbersFromRight = 0;
  let showCorrects = true;
  if (sportkryssetResultData) {
    for (let i = 0; i < sportkryssetBetData.length; i++) {
      if (sportkryssetBetData[i] === sportkryssetResultData[i]) {
        correctNumbersFromLeft++;
      } else {
        break;
      }
    }
    if (correctNumbersFromLeft < sportkryssetBetData.length) {
      for (let i = sportkryssetBetData.length - 1; i >= 0; i--) {
        if (sportkryssetBetData[i] === sportkryssetResultData[i]) {
          correctNumbersFromRight++;
        } else {
          break;
        }
      }
    } else {
      showCorrects = false;
    }
  } else {
    showCorrects = false;
  }
  const row = sportkryssetBetData.map((bettedNumber, index) => ({
    number: bettedNumber,
    isCorrect: sportkryssetResultData ? bettedNumber === sportkryssetResultData[index] : false
  }));
  return {
    correctNumbersFromLeft,
    correctNumbersFromRight,
    row,
    showCorrects
  };
};
const setUpSportKryssData = _ref2 => {
  let {
    sportkryssetResultData,
    sportkryssetBetData
  } = _ref2;
  if (!sportkryssetResultData && !sportkryssetBetData) {
    return undefined;
  }
  if (sportkryssetBetData) {
    return setUpBetDataWithResult({
      sportkryssetResultData,
      sportkryssetBetData
    });
  }
  if (sportkryssetResultData && !sportkryssetBetData) {
    return setUpResultData(sportkryssetResultData);
  }
};
setGlobal('svs.components.tipsen.SportkryssMybetsResult.utils', {
  setUpSportKryssData
});

 })(window);